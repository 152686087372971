<template>
  <div class="relative bg-white w-full">
    <div ref="rubinItemDetails"></div>
    <div
      v-if="set.setId"
      class="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8"
    >
      <div class="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
        <!-- Image gallery -->
        <div class="flex flex-col-reverse">
          <!-- Image selector -->
          <div class="mx-auto mt-6 w-full max-w-2xl lg:max-w-none">
            <div
              :class="{
                'flex space-x-2': set.vars.color.length < 7,
                'grid grid-cols-6 gap-2': set.vars.color.length > 6,
              }"
              aria-orientation="horizontal"
              role="tablist"
            >
              <template v-for="(color, colorIdx) in set.vars.color">
                <router-link
                  v-if="color.setId !== set.setId"
                  :key="colorIdx"
                  :to="{
                    name: 'Set',
                    params: {
                      set: color.setId,
                      details:
                        color.alloy +
                        '-' +
                        color.height +
                        '-' +
                        color.stoneQuality,
                    },
                  }"
                  class="
                    flex-1
                    relative
                    flex
                    h-24
                    cursor-pointer
                    items-center
                    justify-center
                    rounded-md
                    bg-white
                    text-sm
                    font-medium
                    uppercase
                    text-gray-900
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring
                    focus:ring-opacity-50
                    focus:ring-offset-4
                    focus:ring-brand-light
                  "
                  aria-controls="tabs-1-panel-1"
                  role="tab"
                  type="button"
                >
                  <span class="absolute inset-0 overflow-hidden rounded-md">
                    <img
                      :src="color.image"
                      :alt="color.text"
                      :title="color.text"
                      class="h-full w-full object-cover object-center"
                    />
                  </span>
                  <span
                    class="
                      ring-transparent
                      pointer-events-none
                      absolute
                      inset-0
                      rounded-md
                      ring-2 ring-offset-2
                    "
                    aria-hidden="true"
                  ></span>
                </router-link>
              </template>
            </div>
          </div>

          <div class="aspect-w-1 aspect-h-1 w-full">
            <!-- Tab panel, show/hide based on tab state. -->
            <div
              id="tabs-1-panel-1"
              aria-labelledby="tabs-1-tab-1"
              role="tabpanel"
              tabindex="0"
            >
              <img
                :src="set.image"
                :alt="set.setId + ' ' + set.description.alloy"
                class="h-full w-full object-cover object-center sm:rounded-lg"
              />
            </div>
          </div>
        </div>

        <!-- Product info -->
        <div class="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
          <p class="text-3xl font-light tracking-tight text-brand-text">
            {{ set.description.title }}
          </p>

          <div v-if="showPrice" class="mt-3">
            <p class="text-3xl tracking-tight text-brand-main">
              {{ $n(set.price.raw, "currency") }}
            </p>
          </div>

          <div class="mt-6">
            <p class="space-y-6 text-base text-gray-700">
              {{ set.description.description }}
            </p>
          </div>

          <div class="mt-6">
            <div>
              <p class="text-base text-brand-text font-bold">
                {{ $t("details.metal.title") }}
              </p>

              <div class="mt-2">
                <label class="sr-only">
                  {{ $t("details.metal.srOnlyCta") }}
                </label>
                <div
                  class="flex flex-col space-y-3 text-sm text-gray-600 md:w-2/3"
                >
                  <label
                    v-for="(metal, metalIdx) in set.vars.metal"
                    :key="metalIdx"
                    class="text-sm"
                    :class="{
                      'font-bold text-brand-main': metal.alloy === set.alloy,
                      'text-gray-600': metal.alloy !== set.alloy,
                    }"
                  >
                    <router-link
                      :to="{
                        name: 'Set',
                        params: {
                          set: metal.setId,
                          details:
                            metal.alloy +
                            '-' +
                            metal.height +
                            '-' +
                            metal.stoneQuality,
                        },
                      }"
                    >
                      <div class="flex">
                        <p class="flex-grow">{{ metal.text }}</p>
                        <p class="text-right">
                          {{ $n(metal.price, "currency") }}
                        </p>
                      </div>
                    </router-link>
                  </label>
                </div>
              </div>
            </div>

            <div class="mt-10 xl:flex xl:space-x-2 space-y-2 xl:space-y-0">
              <a
                v-if="showConfiguratorLink"
                target="_blank"
                :href="
                  configuratorHost +
                  set.setId.toUpperCase() +
                  '|' +
                  set.alloy.toUpperCase() +
                  '|' +
                  set.height +
                  '|' +
                  set.stoneQuality +
                  '|DEFAULT'
                "
                class="
                  flex flex-1
                  items-center
                  justify-center
                  rounded-md
                  border border-transparent
                  bg-brand-light
                  py-3
                  px-4
                  text-base
                  font-medium
                  text-brand-text
                  hover:bg-brand-light hover:text-brand-text
                  focus:outline-none
                  focus:ring-2
                  focus:ring-brand-text
                  focus:ring-offset-2
                  focus:ring-offset-gray-50
                  sm:w-full
                  whitespace-nowrap
                "
              >
                {{ $t("details.buttons.configurator") }}
              </a>
              <a
                v-if="appointmentLink !== ''"
                target="_blank"
                :href="appointmentLink"
                class="
                  flex flex-1
                  items-center
                  justify-center
                  rounded-md
                  border border-transparent
                  bg-brand-main
                  py-3
                  px-4
                  text-base
                  font-medium
                  text-white
                  hover:bg-brand-light hover:text-brand-text
                  focus:outline-none
                  focus:ring-2
                  focus:ring-brand-text
                  focus:ring-offset-2
                  focus:ring-offset-gray-50
                  sm:w-full
                  whitespace-nowrap
                "
              >
                {{ $t("details.buttons.appointment") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { Set } from "@/assets/ts/set";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  name: "Set",
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const set = ref({});
    const rubinItemDetails = ref(null);

    const SetObj = new Set();

    const getSet = (setId, details) => {
      if (setId !== "" && typeof details != "undefined") {
        store.commit("loading/actionStarted");
        SetObj.get(setId, details).then((data) => {
          set.value = data;
          store.commit("loading/actionFinished");
          rubinItemDetails.value.scrollIntoView({ behavior: 'smooth' })
        });
      }
    };

    getSet(route.params.set, route.params.details);

    watch(
      () => route.params,
      (current) => {
        getSet(route.params.set, route.params.details);
      },
      { deep: true }
    );

    /**
     * catalog config
     */

    let showPrice = false
    let showConfiguratorLink = false
    let appointmentLink = ""

    if (typeof window.rubinCatalog !== "undefined") {
      showPrice =
          typeof window.rubinCatalog.details.price === "boolean"
              ? window.rubinCatalog.details.price
              : true;
      showConfiguratorLink =
          typeof window.rubinCatalog.details.configuratorLink === "boolean"
              ? window.rubinCatalog.details.configuratorLink
              : true;
      appointmentLink =
          typeof window.rubinCatalog.details.appointmentLink === "string" &&
          window.rubinCatalog.details.appointmentLink !== ""
              ? window.rubinCatalog.details.appointmentLink
              : "";
    }

    /**
     * appointment link
     */

    if (appointmentLink !== "") {
      if (appointmentLink.indexOf("?") === -1) {
        appointmentLink += "?url=" + encodeURIComponent(document.location.href);
      } else {
        appointmentLink += "&url=" + encodeURIComponent(document.location.href);
      }
    }

    /**
     * configurator link
     */
    const configuratorHost = computed(() => {
      if (set.value.setId.substring(0, 2) === "Q-") {
        return "https://www.123gold.de/konfigurator/#";
      } else {
        return "https://www.rubin-trauringe.de/konfigurator/#";
      }
    });

    return {
      set,
      showPrice,
      showConfiguratorLink,
      appointmentLink,
      configuratorHost,
      rubinItemDetails,
    };
  },
};
</script>
