<template>
  <div id="rubin-catalog" class="relative bg-white w-full py-8">
    <div v-if="catalogSets.length > 0" class="max-w-screen-xl mx-auto">
      <ul class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        <li
          v-for="(set, setIdx) in catalogSets"
          :key="setIdx"
          class="p-4 hover:shadow-md rounded-md"
        >
          <catalog-set :set="set" />
        </li>
      </ul>
      <div v-if="itemsLeft > 0" class="flex my-4">
        <div class="mx-auto">
          <button
            class="
              more-button
              text-sm
              cursor-pointer
              border border-brand-main
              text-brand-main
              uppercase
              tracking-widest
              bg-white
              hover:bg-brand-main hover:text-white
              px-10
              py-2
            "
            @click="showMore"
          >
            {{ $t("catalog.showMore.button", { items: itemsLeft }) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { Catalog } from "@/assets/ts/catalog";
import { useStore } from "vuex";
import CatalogSet from "@/components/catalog/set";

export default {
  name: "Catalog",
  components: { CatalogSet },
  setup() {
    /**
     * catalog config
     */

    const rawCatalogSets = ref([]);
    const currentItems = ref(itemsInBlock);

    let catalogId = ref(0);
    let showAll = ref(false);
    let moreCatalogs = ref(false);
    const itemsInBlock = 9;

    /**
     * catalog init
     */

    const init = () => {
      if (typeof window.rubinCatalog !== "undefined") {
        catalogId.value =
          typeof window.rubinCatalog.catalog.id === "number"
            ? window.rubinCatalog.catalog.id
            : 27;
        showAll.value =
          typeof window.rubinCatalog.catalog.showAll === "boolean"
            ? window.rubinCatalog.catalog.showAll
            : false;

        moreCatalogs.value =
          typeof window.rubinCatalog.catalog.moreCatalogs === "boolean"
            ? window.rubinCatalog.catalog.moreCatalogs
            : false;

        loadCatalog();
      }
    };

    const loadCatalog = () => {
      /**
       *
       */
      if (catalogId.value === 18)
        catalogId.value = 58
      else if (catalogId.value === 17)
        catalogId.value = 57
      else if (catalogId.value === 21)
        catalogId.value = 49
      else if (catalogId.value === 27)
        catalogId.value = 42
      else if (catalogId.value === 37)
        catalogId.value = 65
      else if (catalogId.value === 36)
        catalogId.value = 64
      else if (catalogId.value === 30)
        catalogId.value = 44
      else if (catalogId.value === 29)
        catalogId.value = 63
      else if (catalogId.value === 14)
        catalogId.value = 61
      else if (catalogId.value === 3)
        catalogId.value = 60
      else if (catalogId.value === 12)
        catalogId.value = 53
      else if (catalogId.value === 2)
        catalogId.value = 52
      /**
       * Gets the items from the catalog API
       */
      const CatalogObj = new Catalog();
      const store = useStore();

      store.commit("loading/actionStarted");
      CatalogObj.get(catalogId.value).then((data) => {
        rawCatalogSets.value = data;
        store.commit("loading/actionFinished");
      });
    };

    init();

    /**
     * Extract the items that should be shown.
     */

    const catalogSets = computed(() => {
      if (showAll.value) {
        return rawCatalogSets.value;
      } else {
        return rawCatalogSets.value.slice(0, currentItems.value);
      }
    });

    /**
     * Adds more items to be shown.
     */
    const showMore = () => {
      currentItems.value += itemsInBlock;
    };

    /**
     * Number of items that are not yet shown
     */
    const itemsLeft = computed(() => {
      return rawCatalogSets.value.length - currentItems.value;
    });

    return { catalogId, catalogSets, showMore, itemsLeft, moreCatalogs };
  },
};
</script>
